import React from "react";
import PropTypes from "prop-types";
import FacebookProvider, { Comments as FBComments } from "react-facebook";
import Disqus from 'disqus-react';

import config from "../../../content/meta/config";

const Comments = props => {
  const { facebook, slug, theme, data } = props;

  const postTitle = ((data || {}).frontmatter || {}).title;
  var config_url = config.siteUrl+slug;
  const disqusConfig = {
    url: config_url,
    identifier: config_url
}

  return (
    <React.Fragment>
      <div id="post-comments" className="comments">
        {/* <FacebookProvider appId={facebook.appId}>
          <FBComments href={`${config.siteUrl}${slug}`} width="100%" colorscheme="light" />
        </FacebookProvider> */}
        <Disqus.CommentCount shortname={config.disqusShortname} config={disqusConfig}>
                    Comments
        </Disqus.CommentCount>
        <p>{postTitle}</p>
        <Disqus.DiscussionEmbed shortname={config.disqusShortname} config={disqusConfig} />
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        div.comments {
          margin: 0 -8px ${theme.space.default};
          background-color: black !important;
          border-radius: 5px;
          padding: 5px;
        }
        
      `}</style>
    </React.Fragment>
  );
};

Comments.propTypes = {
  slug: PropTypes.string.isRequired,
  facebook: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Comments;
